<template>
  <div>
    <el-drawer
      title="服务回访项目配置"
      :visible.sync="showDrawer"
      direction="ltr"
      size="90%"
      :destroy-on-close="true"
      :show-close="true"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="df-row" :style="[style]">
        <!-- 回访项目设置 -->
        <div class="df-col left-box">
          <div ref="SettingLeftTop" class="top">
            <div class="tips-box">
              <!-- 提示区域 -->
              <div>
                <div>
                  <i class="el-icon-warning-outline"></i>
                  <span>
                    开启手艺人提醒将在到期后为手艺人生成待办事项，提醒手艺人对顾客进行回访</span
                  >
                </div>
                <div>
                  <i class="el-icon-warning-outline"></i>
                  <span>
                    优先向顾客发送微信提醒，发送微信提醒后不再向顾客重复发送短信提醒</span
                  >
                </div>
                <div class="custom" @click="goto">自定义短信模板></div>
              </div>

              <!-- 项目复制设置 -->
              <div class="copy-setting-box">
                <span>复制项目设置到门店</span>

                <div>
                  <el-select
                    v-model="toStoresGuids"
                    multiple
                    collapse-tags
                    size="mini"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in storesList"
                      :key="item.StoresGuid"
                      :label="item.StoresName"
                      :value="item.StoresGuid"
                    >
                    </el-option>
                  </el-select>

                  <el-button
                    type="danger"
                    plain
                    size="mini"
                    :disabled="copyDisabled"
                    @click="copyTrackSettingTo"
                    >执行复制</el-button
                  >
                </div>
              </div>
            </div>

            <!-- <el-form ref="form" label-width="110px">
              <el-form-item label="提醒门店管理员">
                <DfEmplSelect
                  ref="DfEmplSelect"
                  size="small"
                  :emplGuids="[managerInfo.ManagerGuid || '']"
                  :storeList="managerList"
                  :isTags="false"
                  :isDialog="false"
                  :selectMultiple="false"
                  @selected="onSelectedEmpl"
                ></DfEmplSelect>
              </el-form-item>
            </el-form>

            <el-button class="save-btn" type="primary" size="small" @click=""
              >保存</el-button
            > -->
          </div>

          <!-- 项目列表 -->
          <TrackList ref="TrackList" :selectType="selectType"></TrackList>
        </div>

        <!-- 待办事项提醒设置 -->
        <!-- <div class="df-col right-box">
          <span>手艺人待办事项提醒设置</span>

          <div class="remind-text">
            <el-input
              v-model="remindContent"
              placeholder="离会员{顾客昵称}。上次来店做{项目名称}已经{*}天了，请及时回访"
              :rows="3"
              maxlength="192"
              show-word-limit
              type="textarea"
              @change=""
            ></el-input>

            <el-button type="danger" size="mini" @click="">保存</el-button>
          </div>

          <TemplateView></TemplateView>
        </div> -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Item from "@/api/item.js";

import Remind from "@/views/marketing/components/remind.vue";
// import TemplateView from "./components/template-view.vue";
import TrackList from "./components/track-list.vue";

export default {
  components: {
    Remind,
    // TemplateView,
    TrackList,
  },

  props: {
    code: {
      type: String,
      default: () => "",
    },

    selectType: {
      type: String,
      default: () => "",
    },
  },

  data() {
    return {
      storesList: [], // 门店列表
      toStoresGuids: [], // 选中门店
      style: {
        "--top-div-height": "",
      },
      showDrawer: false, // 抽屉显示
      copyDisabled: false, // 复制防抖
    };
  },

  methods: {
    // 监听元素高度
    async handleResize() {
      let settingLeftTopDom = this.$refs.SettingLeftTop;
      // settingLeftMiddleDom = this.$refs.SettingLeftMiddle;

      let getHeightObserver = await new ResizeObserver((entries) => {
        let topHeight = entries[0].contentRect.height;
        let heightSum = 0;
        // let middleHeight = entries[1].contentRect.height;

        // heightSum += topHeight + middleHeight;
        heightSum += topHeight;
        console.log(entries[0].contentRect);
        this.style["--top-div-height"] = heightSum + 20 + "px";
      });

      await getHeightObserver.observe(settingLeftTopDom);
      // await getHeightObserver.observe(settingLeftMiddleDom);
    },

    // 获取门店列表
    async getStoreSelector() {
      try {
        let { data } = await Item.getStoreSelector();
        this.storesList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 同步设置到其他门店
    copyTrackSettingTo() {
      let that = this;
      let { toStoresGuids } = this;
      if (toStoresGuids.length < 1) {
        return this.$message.info("请选择要复制到的门店");
      }
      that.copyDisabled = true;
      that
        .$confirm("是否确认要将服务设置复制到所选分店？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          try {
            let { data, errcode } = await Item.copyTrackSettingTo({
              ToStoresGuids: toStoresGuids,
            });
            if (errcode == 0) {
              that.$message.success("复制成功");
              that.toStoresGuids = [];
            }
          } catch (err) {
            console.log(err);
          } finally {
            that.copyDisabled = false;
          }
        })
        .catch(() => {
          that.copyDisabled = false;
        });
    },

    // 打开服务回访项目设置抽屉
    onShowDrawer() {
      this.showDrawer = true;
      this.$nextTick(async () => {
        this.getStoreSelector();
        await this.handleResize();
        await this.$refs.TrackList.initPageData();
      });
    },

    // 关闭抽屉
    onCloseDrawer() {
      this.showDrawer = false;
    },

    // 跳转到消息模版
    goto() {
      this.$router.push(`/setting/basic/template?applyType=${this.code}`);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-drawer__header {
  margin-bottom: 10px;
  padding: 10px 20px 0;

  > span {
    font-weight: bold;
    color: black;
  }
}

.df-row {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 20px;
  overflow-y: auto;

  .df-col {
    height: 100%;
    margin-right: 0;
    box-sizing: border-box;
  }

  .left-box {
    width: 100%;
    // width: calc(100% - 375px);

    .top {
      .tips-box {
        .flex-row();

        > div:first-child {
          width: 50%;
          color: #999;

          i {
            font-size: 16px;
          }
        }

        > div:last-child {
          height: 100%;
          span {
            margin-right: 10px;
          }
        }

        .custom {
          width: 120px;
          color: #409eff;
          cursor: pointer;
        }

        .copy-setting-box {
          .flex-row;
          align-items: unset;
          > div {
            .flex-col();
            justify-content: unset;
            align-items: unset;

            .el-select {
              width: 300px;
              margin-bottom: 10px;
            }

            .el-button {
              width: 80px;
            }
          }
        }
      }

      .save-btn {
        margin-left: 80px;
      }
    }

    .middle {
      height: 100px;
      margin-top: 20px;
      overflow-y: auto;
      // background-color: tomato;

      .tabs-list {
        .flex-row();
        flex-wrap: wrap;
        // height: 100%;

        .tabs-item {
          padding: 6px 10px;
          background-color: white;
          border-radius: 6px;
          margin: 4px 4px 0 0;
          border: 1px solid #ccc;
          cursor: pointer;
        }

        .active {
          color: #fff;
          background-color: #f56c6c;
          border-color: #f56c6c;
        }
      }
    }

    .bottom {
      // .flex-row;
      // align-items: unset;
      // margin-top: 20px;
      // height: calc(100% - var(--top-div-height));
      // background-color: black;

      // .menu {
      //   width: 10%;
      //   height: 100%;
      //   overflow-y: auto;
      // }

      // ::v-deep .el-table {
      //   height: calc(100vh - (var(--top-div-height) + 40px));
      //   overflow-y: auto;
      //   .el-table__body tr:hover > td {
      //     background-color: #f6f9ff !important;
      //   }

      //   .setting-box,
      //   .enable-remind {
      //     margin-bottom: 5px;
      //   }

      //   .setting-box {
      //     .flex-row();

      //     > .el-input,
      //     > .el-select {
      //       margin: 0 10px;
      //     }

      //     > .el-input {
      //       width: 60px;
      //     }

      //     > .el-select {
      //       width: 160px;
      //     }

      //     .follow-select {
      //       margin-left: 24px;
      //     }
      //   }
      // }
    }
  }

  .right-box {
    width: 395px;
    padding: 10px;
    background-color: #f1f1f1;

    .remind-text {
      .flex-col();
      align-items: end;
      padding: 10px;
      margin: 10px 0;
      border-radius: 4px;
      background-color: white;
      box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.04);

      .el-button {
        margin-top: 10px;
      }
    }
  }

  .market-popover();
}
</style>
