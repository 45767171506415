<template>
  <el-form ref="form" :label-width="labelWidth">
    <!-- 提醒人群单选器 -->
    <el-form-item :label="title">
      <el-radio-group v-model="radio" @change="onChangeRadio">
        <el-radio :label="0">
          <span>门店员工</span>

          <el-popover v-show="enableTips" placement="top" trigger="hover">
            <div class="content">由顾客的管理员工进行待办事项的处理</div>
            <i class="el-icon-warning-outline" slot="reference"></i>
          </el-popover>
        </el-radio>

        <el-radio :label="1">
          <span>门店管理员</span>

          <el-popover v-show="enableTips" placement="top" trigger="hover">
            <div class="content">指定门店的管理员进行待办事项的处理</div>
            <i class="el-icon-warning-outline" slot="reference"></i>
          </el-popover>
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- 选择门店员工 -->
    <el-form-item v-show="radio == 0 && enableEmpl" label="">
      <div class="empl-select">
        <el-checkbox-group v-model="employeeList" @change="onChangeCheck">
          <el-checkbox label="Follower1">跟踪员工1</el-checkbox>
          <el-checkbox label="Follower2">跟踪员工2</el-checkbox>
          <el-checkbox label="Follower3">跟踪员工3</el-checkbox>
        </el-checkbox-group>

        <el-popover v-show="enableTips" placement="top" width="300" trigger="hover">
          <div class="content">
            <div>特殊情况说明：</div>
            <div>
              1、若同一顾客有多个不同工位的管理员工服务，则优先提醒第一工位员工
            </div>
            <div>
              2、若一个顾客被多个同工位员工服务，则优先提醒服务列表的第一个员工
            </div>
          </div>
          <i class="el-icon-warning-outline" slot="reference"></i>
        </el-popover>
      </div>
    </el-form-item>

    <!-- 选择门店管理员 -->
    <el-form-item v-show="radio == 1 && enableStore" label="">
      <div>
        <DfEmplSelect
          ref="DfEmplSelect"
          size="small"
          :emplGuids="[managerInfo.ManagerGuid || '']"
          :storeList="managerList"
          :isTags="false"
          :isDialog="false"
          :selectMultiple="false"
          @selected="onSelectedEmpl"
        ></DfEmplSelect>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import Marketing from "@/api/marketing.js";

export default {
  props: {
    title:{
      type: String,
      default: () => '提醒到谁'
    },

    dataInfo: {
      type: Object,
      default: () => ({
        employee: {},
        manager: {},
      }),
    },

    labelWidth: {
      type: String,
      default: () => "80px",
    },

    enableTips: {
      type: Boolean,
      default: () => true,
    },

    enableEmpl: {
      type: Boolean,
      default: () => true,
    },

    enableStore: {
      type: Boolean,
      default: () => true,
    },
  },

  watch: {
    dataInfo: {
      handler(newVal) {
        if (newVal) {
          this.employeeInfo = newVal.employee;
          this.managerInfo = newVal.manager;
          this.radio = newVal.employee.Useable ? 0 : 1;
          this.initEmployee();
        }
      },
      deep: true,
      immediate: true
    },
  },

  data() {
    return {
      radio: 0, // 选中单选框下标
      employeeInfo: {}, // 门店员工信息
      managerInfo: {}, // 门店管理员信息
      employeeList: [], // 选中管理员
      managerList: [], // 门店管理员列表
    };
  },

  mounted() {
    this.getManagerSelector();
  },

  methods: {
    // 获取管理员列表
    async getManagerSelector() {
      try {
        let { data } = await Marketing.getManagerSelector();
        this.managerList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 初始化/转换门店员工选中值
    initEmployee(mode = "init") {
      let { employeeInfo, employeeList } = this;
      let arr = [];

      if (mode == "change") {
        arr = {
          Follower1: false,
          Follower2: false,
          Follower3: false,
          Useable: true,
        };
        employeeList.forEach((item, index) => {
          arr[item] = true;
        });
        // console.log(arr, this.employeeList);
        this.employeeInfo = arr;
      } else {
        for (let item in employeeInfo) {
          if (item != "Useable" && employeeInfo[item]) {
            arr.push(item);
          }
        }
        this.employeeList = arr;
      }
    },

    // 单选框改变事件
    onChangeRadio(event) {
      if (event) {
        this.employeeInfo.Useable = false;
        this.managerInfo.Useable = true;
      }else{
        this.managerInfo.Useable = false;
        this.employeeInfo.Useable = true;
      }
      this.returnBack()
    },

    // 多选框改变事件
    async onChangeCheck(event) {
      await this.initEmployee("change");
      await this.$nextTick(()=>{
        this.returnBack()
      });
    },

    // 选中员工
    onSelectedEmpl(event) {
      this.managerInfo.ManagerGuid = event.emplGuids[0];
      this.returnBack()
    },

    // 父组件通信
    returnBack(){
      this.$emit('change', {employee: this.employeeInfo,  manager: this.managerInfo,});
    }
  },
};
</script>

<style lang="less" scoped>
.el-form {
  .el-form-item {
    display: table;

    ::v-deep .el-form-item__content {
      // margin-left: 0 !important;
    }

    .empl-select {
      display: flex;
      flex-direction: row;
      //   align-items: center;
      padding: 10px;
      line-height: 0;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);

      .el-checkbox-group {
        width: unset;
        flex: unset;
      }

      i {
        margin-left: 20px;
      }
    }
  }
}

.market-i(3px);

i {
  line-height: 1;
}

.down-select {
  display: none !important;
}

.market-popover();
</style>
