<template>
  <div class="tracking">
    <div class="setting-box">
      <el-form label-width="80px">
        <!-- <el-form-item label="回访商品:">
          <div class="gifts"> -->
        <!-- 回访红包 -->
        <!-- <div class="df-row">
              <span>推荐赠送的红包:</span>

              <el-popover placement="right" width="340" trigger="hover">
                <div class="content">
                  <div>进行顾客服务回访时，</div>

                  <div>
                    管理员或员工可以在美管加APP或小掌柜上选择发送以下类型的推荐红包，
                    联系顾客进店消费
                  </div>
                </div>

                <i class="el-icon-warning-outline" slot="reference"></i>
              </el-popover>

              <span class="click">+ 选择红包</span>
            </div> -->

        <!-- 回访商品 -->
        <!-- <div class="df-row">
              <DfGoodsSelect></DfGoodsSelect>
            </div> -->
        <!-- </div>
        </el-form-item> -->

        <!-- 回访设置 -->
        <el-form-item label="回访设置:">
          <div class="df-row" style="margin-bottom: 0;">提醒到-门店员工</div>

          <div class="df-row">
            <Remind
              ref="Remind"
              title=""
              labelWidth="0px"
              :dataInfo="{ employee: remindEmployee, manager: remindManager }"
              :enableTips="false"
              :enableEmpl="false"
              @change="onChangeRemind"
            ></Remind>
            <!-- <DfEmplSelect
              ref="DfEmplSelect"
              size="small"
              :emplGuids="[managerInfo.ManagerGuid || '']"
              :storeList="managerList"
              :isTags="false"
              :isDialog="false"
              :selectMultiple="false"
              @selected="onSelectedEmpl"
            ></DfEmplSelect> -->
          </div>

          <div class="df-row">
            <el-button
              type="primary"
              size="small"
              plain
              @click="onShowItemSetting"
              >编辑配置</el-button
            >
          </div>

          <div class="df-row">
            <el-button type="primary" size="small" @click="saveFeature">保存</el-button>
          </div>
        </el-form-item>
      </el-form>

      <!-- <el-divider></el-divider> -->
    </div>

    <!-- <div class="table-box">
      <div class="df-row">
        <div class="df-col">
          <el-button type="primary" size="small" @click="">查询</el-button>
        </div>
      </div>

      <el-table :data="tableData" border stripe>
        <el-table-column label="项目编号"></el-table-column>
        <el-table-column label="项目名称"></el-table-column>
        <el-table-column label="跟踪期限"></el-table-column>
        <el-table-column label="期限单位"></el-table-column>
        <el-table-column label="提前通知天数"></el-table-column>
        <el-table-column label="过期继续通知天数"></el-table-column>
        <el-table-column label="短信内容"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="">修改</el-button>
            <el-button type="danger" size="mini" @click="">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div> -->

    <ItemSettingDrawer ref="ItemSettingDrawer" :code="code" :selectType="remindManager.Useable ? 'manager' : 'employee'"></ItemSettingDrawer>
  </div>
</template>

<script>
import Marketing from "@/api/marketing.js";
import Template from "@/api/template.js";

import Remind from "../components/remind.vue";
import ItemSettingDrawer from "./components/item-setting-drawer.vue";

export default {
  components: { ItemSettingDrawer, Remind },

  data() {
    return {
      code: "ExpeTracking", // 功能code
      originalInfo: {}, // 门店管理员原数据
      remindEmployee: {}, // 门店员工信息
      remindManager: {}, // 门店管理员信息
      tableData: [],
    };
  },

  mounted() {
    this.getFeatureData();
  },

  methods: {
    // 获取功能数据
    async getFeatureData() {
      try {
        let remindEmployee = {
            Useable: false,
          },
          remindManager = {
            ManagerGuid: "",
            Useable: false,
          };
        let { data } = await Marketing.getFeatureData({
          code: this.code,
        });
        remindManager.ManagerGuid = data.RemindManagerGuid;
        if (data.RemindManagerUseable) {
          remindManager.Useable = true;
        } else {
          remindEmployee.Useable = true;
        }

        this.originalInfo = data
        this.remindEmployee = remindEmployee;
        this.remindManager = remindManager;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存功能数据
    async saveFeature() {
      let { remindEmployee, remindManager, code } = this;
      // console.log(remindEmployee, remindManager);
      let RemindManager = {
        RemindManagerGuid: remindManager.ManagerGuid,
        RemindManagerUseable: remindManager.Useable,
        RemindEmployeeUseable: remindEmployee.Useable,
      };

      try {
        let submitData = {
          Code: code,
          Data: RemindManager,
        };
        let { errcode } = await Marketing.saveFeature(submitData);
        if (errcode == 0) {
          this.$message.success("设置保存成功!");
          this.getFeatureData();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 打开回访项目配置抽屉
    onShowItemSetting() {
      this.saveTips() ? this.$refs.ItemSettingDrawer.onShowDrawer() : '';
    },

    // 提醒员工选中
    async onChangeRemind(event) {
      this.remindEmployee = event.employee;
      this.remindManager = event.manager;
      this.saveTips()
    },

    // 改变员工未保存拦截
    saveTips(){
      if(this.originalInfo.RemindManagerUseable == this.remindManager.Useable){
        return true
      }
      this.$message.info('更改提醒人员设置后需进行【保存】')
    }
  },
};
</script>

<style lang="less" scoped>
.tracking {
  .setting-box {
    padding-top: 20px;
    .gifts {
      //   padding: 3px 10px;
      //   border: 1px solid #eee;

      .df-row {
        // margin: 14px 0;
        margin: 0;
        line-height: unset;

        .click {
          margin-left: 30px;
          color: #409eff;
          cursor: pointer;
        }

        .click:hover {
          color: #66b1ff;
        }
      }
    }

    .market-i(0);
    i:hover {
      cursor: unset !important;
      color: #ccc !important;
    }
  }
}
.market-popover();
</style>
