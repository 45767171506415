import {
	request
} from "../utils/axios";

export default {
	// 会员 ===> 顾客营销
	getFeatureData(params = {}) {
		return request('get', `/marketing/get_feature_data`, params);
	},

	saveFeature(params = {}) {
		return request('post', `/marketing/save_feature`, params);
	},

	getManagerSelector(params = {}) {
		return request('get', `/marketing/get_manager_selector`, params);
	},
}