<template>
  <div class="track-list">
    <div class="menu">
      <!-- 项目分类树形组件 -->
      <el-tree
        :data="treeData"
        node-key="id"
        draggable
        :highlight-current="true"
        :default-expanded-keys="defaultExpandedKeys"
        :current-node-key="currentNodeKey"
        @node-click="nodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>
    </div>

    <div class="track-box">
      <!-- 设置项目列表 -->
      <el-table
        :data="trackList"
        border
        :show-header="false"
        stripe
        v-loading="loading"
      >
        <el-table-column width="200">
          <template slot-scope="scope">
            {{ `${scope.row.ItemNo}  ${scope.row.ItemName}` }}
          </template>
        </el-table-column>

        <el-table-column width="160">
          <template slot-scope="scope">
            <div class="enable-remind">
              <el-switch
                v-model="scope.row.RemindMemberUseable"
                :active-value="1"
                :inactive-value="0"
                @change="onChangeTrackSetting(scope.row, scope.$index)"
              >
              </el-switch>
              顾客提醒
            </div>
            <div class="enable-remind">
              <el-switch
                v-model="scope.row.RemindInsiderUseable"
                :active-value="1"
                :inactive-value="0"
                @change="onChangeTrackSetting(scope.row, scope.$index)"
              >
              </el-switch>
              {{ selectType == "manager" ? "管理员" : "手艺人" }}提醒
            </div>
          </template>
        </el-table-column>

        <el-table-column width="760">
          <template slot-scope="scope">
            <div class="setting-box">
              周期
              <el-input
                v-model="scope.row.RemindMemberDays"
                size="mini"
                :clearable="scope.row.RemindMemberDays ? true : false"
                @change="onChangeTrackSetting(scope.row, scope.$index)"
              ></el-input>
              天，微信模板
              <el-select
                v-model="scope.row.RemindMemberWxTplGuid"
                size="mini"
                clearable
                @change="onChangeTrackSetting(scope.row, scope.$index)"
              >
                <el-option
                  v-for="item in wxTemplateList"
                  :key="item.TplGuid"
                  :label="item.TplName"
                  :value="item.TplGuid"
                >
                  <span :title="item.TplContent">{{ item.TplName }}</span>
                </el-option> </el-select
              >，短信模板
              <el-select
                v-model="scope.row.RemindMemberSmsTplGuid"
                size="mini"
                clearable
                @change="onChangeTrackSetting(scope.row, scope.$index)"
              >
                <el-option
                  v-for="item in smsTemplateList"
                  :key="item.TplGuid"
                  :label="item.TplName"
                  :value="item.TplGuid"
                >
                  <span :title="item.TplContent">{{ item.TplName }}</span>
                </el-option>
              </el-select>
            </div>

            <div class="setting-box">
              周期
              <el-input
                v-model="scope.row.RemindInsiderDays"
                size="mini"
                :clearable="scope.row.RemindInsiderDays ? true : false"
                @change="onChangeTrackSetting(scope.row, scope.$index)"
              ></el-input>
              天，{{ selectType == "manager" ? "提醒到管理员" : "手艺人" }}
              <el-select
                class="follow-select"
                v-if="selectType != 'manager'"
                v-model="scope.row.RemindFollowerIndex"
                size="mini"
                :clearable="scope.row.RemindFollowerIndex == 0 ? false : true"
                @change="onChangeTrackSetting(scope.row, scope.$index)"
              >
                <el-option
                  v-for="item in followerList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>

        <el-table-column>
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.SettingGuid"
              type="danger"
              size="small"
              @click="deleteTrackSetting(scope.row)"
              >删除设置</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 25]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecord"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Service from "@/api/service.js";
import Item from "@/api/item.js";
import Template from "@/api/template.js";

export default {
  props: {
    selectType: {
      type: String,
      default: () => {},
    },
  },

  data() {
    return {
      code: "ExpeTracking", // 功能code
      totalRecord: 0, // 总数
      pageIndex: 1, // 页码
      pageSize: 10, // 页数
      treeData: [], // 消费项目树形数据列表
      currentNodeKey: "ALL", // 当前选中节点
      defaultExpandedKeys: ["ALL"], // 默认展开的节点的key的数组
      trackList: [], // 项目设置数据列表
      wxTemplateList: [], // 微信模板列表
      smsTemplateList: [], // 短信模板列表
      followerList: [
        {
          id: 0,
          name: "请选择",
        },
        {
          id: 1,
          name: "跟踪员工1",
        },
        {
          id: 2,
          name: "跟踪员工2",
        },
        {
          id: 3,
          name: "跟踪员工3",
        },
      ], // 跟随员工列表
      loading: false, // 表格加载
      deleteDisabled: false, // 删除防抖
    };
  },

  methods: {
    // 初始化页面数据
    async initPageData() {
      await this.resetPageData();
      await this.getItemshowTree();
      await this.getTrackSettings();
      this.getTemplateSelector(1);
      this.getTemplateSelector(2);
    },

    // 获取消费项目树形数据
    async getItemshowTree() {
      try {
        let { data } = await Service.getItemshowTree();
        this.treeData = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取设置列表
    async getTrackSettings() {
      this.loading = true;
      try {
        let { data } = await Item.getTrackSettings({
          show_guid: this.currentNodeKey,
          page_index: this.pageIndex,
          page_size: this.pageSize,
        });

        this.trackList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 获取分类下的模板
    async getTemplateSelector(applyType = 1) {
      try {
        let { data } = await Template.getTemplateSelector({
          class_code: this.code,
          apply_type: applyType,
        });
        if (applyType == 1) {
          return (this.wxTemplateList = data);
        }
        this.smsTemplateList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 修改设置数据
    async onChangeTrackSetting(event, index) {
      let trackList = JSON.parse(JSON.stringify(this.trackList));

      event.RemindInsiderDays = event.RemindInsiderDays || 0;
      event.RemindMemberDays = event.RemindMemberDays || 0;
      event.RemindFollowerIndex = event.RemindFollowerIndex || 0;
      try {
        let res;
        if (event.SettingGuid) {
          res = await Item.updateTrackSetting(event);
        } else {
          res = await Item.addTrackSetting(event);
        }
        let { data, errcode } = res;
        if (errcode == 0) {
          this.$message.success("保存成功");
          trackList[index] = data;
          this.trackList = trackList;
          //   console.log(this.trackList[index]);
        }
      } catch (err) {
        trackList[index].RemindMemberUseable = 0;
        this.trackList = trackList;
        console.log(err);
      }
    },

    // 删除设置数据
    deleteTrackSetting(event) {
      let that = this;
      that.deleteDisabled = true;
      that
        .$confirm(
          `确定要删除【${event.ItemNo} ${event.ItemName}】的设置吗？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(async () => {
          try {
            let { errcode } = await Item.deleteTrackSetting({
              item_guid: event.ItemGuid,
            });
            if (errcode == 0) {
              that.$message.success("删除成功");
              that.getTrackSettings();
            }
          } catch (err) {
            console.log(err);
          } finally {
            that.deleteDisabled = false;
          }
        })
        .catch(() => {
          that.deleteDisabled = false;
        });
    },

    // 树形节点点击事件
    nodeClick(data, dragNode, enterNode) {
      this.currentNodeKey = data.id;
      this.defaultExpandedKeys = [data.id];
      this.resetPageData();
      this.getTrackSettings();
    },

    // 重置页面数据
    resetPageData() {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.totalRecord = 0;
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.getTrackSettings();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.getTrackSettings();
    },
  },
};
</script>

<style lang="less" scoped>
.track-list {
  .flex-row;
  align-items: unset;
  margin-top: 20px;
  height: calc(100% - var(--top-div-height));
  width: 100%;
  .menu {
    width: 160px;
    height: 100%;
    overflow-y: auto;
  }

  .track-box {
    // flex: 1;
    width: calc(100% - 160px);

    ::v-deep .el-table {
      width: 100%;
      height: calc(100vh - (var(--top-div-height) + 100px));
      overflow-y: auto;
      .el-table__body tr:hover > td {
        background-color: #f6f9ff !important;
      }

      .setting-box,
      .enable-remind {
        margin-bottom: 5px;
      }

      .setting-box {
        .flex-row();

        > .el-input,
        > .el-select {
          margin: 0 10px;
        }

        > .el-input {
          width: 60px;
        }

        > .el-select {
          width: 160px;
        }

        .follow-select {
          margin-left: 24px;
        }
      }
    }

    .el-pagination {
      margin-top: 10px;
      float: right;
    }
  }
}
</style>
